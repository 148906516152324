/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

.cdk-overlay-container {
    pointer-events: auto !important;
}

.dropdown-item {
    cursor: pointer;
}

.disabled {
    pointer-events: none;
}

textarea.disabled{
    background-color: #e9ecef !important
}

/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
